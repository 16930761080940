<template>
    <div class="datasets-container">
        <div class="datasets-panel-container">
            <please-wait-spinner v-if="filterLoading && !isRender" />
            <div>
                <CommonTabsComponent
                    ref="commonTableTabs"
                    :tabList="dataGrids"
                    @tabChanged="tabChanged"
                    :activeTab="activeTabDataSet"
                    :closeableTabs="true"
                    @closeTab="closeTab"
                >
                    <template v-slot:tabs-end>
                        <b-nav-item
                            class="title-item-class tabs-end"
                            role="presentation"
                            href="#"
                            @click="openPicker"
                            ><img
                                src="../../assets/plus-icon.svg"
                                class="mx-2 plusDataset-img"
                            />
                            <p>Add Dataset</p>
                        </b-nav-item>
                    </template>
                </CommonTabsComponent>
                <div class="tools-tab-btn-wrapper">
                    <div class="tabs-wrapper">
                        <common-tabs-component
                            ref="commonToolTabs"
                            :tabList="toolTabs"
                            @tabChanged="dataSetTabChanged"
                            :activeTab="displayedToolTab"
                        />
                    </div>
                    <div
                        class="btn-wrapper"
                        v-if="displayedToolTab === 'Data Table'"
                    >
                        <b-form-select
                            class="actions-dropdown mr-2 btn-wrapper__el"
                            v-model="selectedAction"
                            :options="actions"
                            @change="performAction"
                            v-if="displayedToolTab === 'Data Table'"
                        ></b-form-select>
                        <button
                            type="button"
                            class="btn buttonSelect open-project-btn btn-secondary item-btn btn-wrapper__el"
                            @click="saveFilter"
                            v-if="displayedToolTab === 'Data Table'"
                        >
                            Save filters
                        </button>
                    </div>
                </div>

                <div class="flt-tbl datagrid-container">
                    <!-- TODO: try to use keep-alive since grid load takes time  -->
                    <KeepAlive
                        :include="[
                            'ScatterPlotSidebar',
                            'advancedfilters',
                            'PagedDataGrid',
                        ]"
                    >
                        <component
                            v-if="datasetId !== 0"
                            :ref="componentName"
                            v-bind:is="componentName"
                            v-bind="currentProperties"
                            @close="$emit('close')"
                            @storeGrid="storeGrid($event)"
                            @exportData="exportData($event)"
                            @navigateToDataSetTab="navigateToDataSetTab()"
                            @resetActionDropdown="resetActionDropdown()"
                        />
                    </KeepAlive>
                </div>
            </div>
        </div>
        <datasetpicker
            @addToDataGrids="addToDataGrids"
            @openPicker="openPicker()"
            ref="datasetPicker"
        ></datasetpicker>
        <SaveFiltersModal
            :isVisible="isVisible"
            @closeModal="closeModal"
            :projectIds="parseInt(ProjectID)"
            :datasetId="datasetId"
        ></SaveFiltersModal>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { datasetService } from '../../services/dataset.service';
import { commonService } from '../../services/common.service';
import _ from 'lodash';
import { getComponentName } from '../../utilities/oneMapTools.js';
import PleaseWaitSpinner from '../CommonComponents/PleaseWaitSpinner.vue';
import SaveFiltersModal from '../Modals/SaveFiltersModal.vue';
import moment from 'moment';
import { gridFilters } from '../../store/gridFilters.module';

export default {
    name: 'projectdatasets',
    components: {
        SaveFiltersModal,
        PleaseWaitSpinner,
        SubMenus: () => import('../CommonComponents/Sidebars/SubMenus.vue'),
        DataPostSidebar: () =>
            import('../CommonComponents/DataPostingTools/DataPostSidebar.vue'),
        ContourSidebar: () =>
            import('../CommonComponents/DataPostingTools/ContourSidebar.vue'),
        PagedDataGrid: () => import('../CommonComponents/PagedDataGrid.vue'),
        CommonTabsComponent: () =>
            import('../CommonComponents/CommonTabsComponent.vue'),
        DatasetToFileExport: () => import('../DatasetToFileExport.vue'),
        XyscatterPlot: () => import('./XyscatterPlot.vue'),
        TitleBlock: () => import('./TitleBlock.vue'),
        AdvancedFilters: () => import('./AdvancedFilters.vue'),
        StandardCrosstab: () => import('./StandardCrosstab.vue'),
        datasetpicker: () => import('../CommonComponents/DatasetPicker.vue'),
    },
    props: {
        toolID: Number,
    },
    data() {
        return {
            filterLoading: true,
            dataGrids: [],
            projectIds: '',
            filterId: 0,
            isRender: true,
            datasetId: 0,
            datasetType: '',
            datatool: false,
            showPage: true,
            gridFiltersHistory: [], // get grid filters
            selectedGridFilters: [], // set grid filters
            userPortalOptionID: 0,
            filterDescription: '',
            filterName: '',
            filterCategory: '',
            dataToSave: [],
            toolTabs: [],
            displayedToolTab: '',
            activeTabDataSet: '',
            componentName: '',
            dataTools: [
                'Data Table',
                'Advanced Filters',
                'Dataset Export',
                'Data Posting Tool',
                'Title Block',
                'X/Y Scatter Plot',
                'Contour',
                'Standard Crosstab',
            ],
            thisGrid: null,
            dataSetName: '',
            isVisible: false,
            gridData: [],
            selectedAction: null,
            currentTabIndex: 0,
            dateFilterColumn: null,
            formCategoryName: '',
            canExportPDF: false,
        };
    },
    methods: {
        ...mapMutations('common', ['setToastMessage']),
        openPicker() {
            this.$refs['datasetPicker'].$refs['modal-datasetPicker'].show();
        },
        resetActionDropdown() {
            this.selectedAction = null;
        },
        closePicker() {
            this.$refs['datasetPicker'].$refs['modal-datasetPicker'].hide();
        },
        performAction(args) {
            let activeDataset = this.$refs['paged-data-grid'].datasetId;
            switch (args) {
                case 'freeze':
                    this.$refs['paged-data-grid'].$refs[
                        'freeze-component'
                    ].$refs['modal-freeze'].show();
                    this.$nextTick(() => {
                        this.selectedAction = null;
                    });
                    break;
                case 'Last12hours':
                case 'Last24hours':
                case 'Last48hours':
                case 'Last3days':
                case 'Lastweek':
                case 'Lastmonth':
                case 'Lastquarter':
                case 'Lastyear':
                case 'Alltime':
                    if (
                        Object.keys(this.groupedTables).length == 0 ||
                        !Object.keys(this.groupedTables).includes(
                            activeDataset.toString()
                        )
                    ) {
                        this.$refs['paged-data-grid'].quickDateFilterAction(
                            args
                        );
                    } else {
                        for (var k in this.groupedTables) {
                            if (typeof this.groupedTables[k] !== 'function') {
                                this.$refs[
                                    'paged-data-grid'
                                ].quickDateFilterGrouped(
                                    args,
                                    k,
                                    this.groupedTables[k].DateFilterColumn
                                );
                            }
                        }
                    }
                    // I don't want to move this to it's own case since it also needs all the above handling
                    if (args == 'Alltime') {
                        this.$store.dispatch(
                            'emergencyManagement/clearQuickTimeFilter'
                        );
                        break;
                    }
                    break;
                //More actions to come
            }
        },
        closeModal() {
            this.isVisible = false;
        },
        saveFilter() {
            this.isVisible = true;
        },
        storeGrid(grid) {
            this.thisGrid = grid;
            this.gridData = [...this.thisGrid.getCurrentViewRecords()];
        },
        exportData(type) {
            let fileName =
                `${this.getExportFileName()}.${type.toLowerCase()}` + '';
            if (type === 'XLSX') {
                this.thisGrid
                    .excelExport({
                        fileName,
                    })
                    .then((res) => {
                        this.setToastMessage({
                            showSuccess: true,
                            successToastMessage: 'File exported successfully.',
                        });
                    })
                    .catch((error) => {
                        this.setToastMessage({
                            showFailure: true,
                            failureToastMessage: 'No data to export!',
                        });
                    });
            } else if (type === 'CSV') {
                this.thisGrid
                    .csvExport({
                        fileName,
                    })
                    .then((res) => {
                        this.setToastMessage({
                            showSuccess: true,
                            successToastMessage: 'File exported successfully.',
                        });
                    })
                    .catch((error) => {
                        this.setToastMessage({
                            showFailure: true,
                            failureToastMessage: 'No data to export!',
                        });
                    });
            }
        },
        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),
        ...mapActions('gridFilters', {
            clearCurrentGridFilter: 'clearCurrentGridFilter',
            setCurrentUUID: 'setCurrentUUID',
            removeSelectedDatasets: 'removeSelectedDatasets',
        }),

        dataSetTabChanged(newTab) {
            this.displayedToolTab = newTab.name;
        },
        tabChanged(newTab) {
            this.selectedAction = null;
            if (this.dataGrids.length !== 0) {
                let newDatagrid = this.dataGrids.find(
                    (tool) => tool.name === newTab.name
                );
                this.datasetId = newDatagrid.DatasetId;
                this.dataSetName = newDatagrid.DatasetAlias;
                this.datasetType = newDatagrid.DatasetType;
                this.dateFilterColumn = newDatagrid.DateFilterColumn;
                this.gridType = newDatagrid.DataExportType.replace(
                    'dse_SP_ExportSamples_',
                    ''
                );
                this.canExportPDF = newDatagrid.CanExportPDF;

                this.$store.commit('projects/datasetIdChanged', this.datasetId);
                if (
                    'LegacyProjectIds' in newDatagrid &&
                    newDatagrid.LegacyProjectIds.length > 0
                ) {
                    this.$store.commit(
                        'gridFilters/mutateDataGridProjectId',
                        newDatagrid.LegacyProjectIds[0]
                    );
                }
                this.$store.commit('datasources/mutateDatagrid', newDatagrid);
            }
        },
        openModel() {
            this.$refs['modal-1'].show();
        },
        forceReset() {
            this.filterDescription = '';
            this.filterName = '';
            this.filterCategory = '';
        },
        showToolPane(tabName) {
            if (!this.tabNames.includes(tabName)) {
                this.toolTabs.push({ name: tabName });
            }
            this.displayedToolTab = tabName;
        },
        getExportFileName() {
            let selectedProject = this.getSelectedProject[0];
            var stillUtc = moment.utc().toDate();
            let dateTime = moment(stillUtc)
                .local()
                .format('yyyyMMDDss');

            return `${selectedProject.ProjectID}_${
                selectedProject.ProjectName
            }_${this.dataSetName.trim()}_${dateTime}`
                .split(' ')
                .join('_');
        },
        navigateToDataSetTab() {
            this.isRender = false;
            this.filterLoading = true;
            if (this.currentGridFilter && this.currentGridFilter.dataSetId) {
                this.$refs['datasetPicker'].addRetrievedDataset(
                    this.currentGridFilter.dataSetId
                );
                let newDatagrid = this.dataGrids.find(
                    (tool) =>
                        tool.DatasetId === this.currentGridFilter.dataSetId
                );
                this.activeTabDataSet = newDatagrid.name;
                let oldDatasetId = this.datasetId;
                this.datasetId = newDatagrid.DatasetId;
                this.dataSetName = newDatagrid.DatasetName;
                this.dateFilterColumn = newDatagrid.DateFilterColumn;
                //clear currentGridFilter
                this.clearCurrentGridFilter();
                if (this.datasetId === oldDatasetId) {
                    this.setCurrentUUID();

                    setTimeout(() => {
                        this.$refs['paged-data-grid'].setFreeze({
                            row: 0,
                            col: 0,
                        });
                    }, 150);
                }

                this.$store.commit('projects/datasetIdChanged', this.datasetId);

                if (
                    'LegacyProjectIds' in newDatagrid &&
                    newDatagrid.LegacyProjectIds.length > 0
                ) {
                    this.$store.commit(
                        'gridFilters/mutateDataGridProjectId',
                        newDatagrid.LegacyProjectIds[0]
                    );
                }
            }
            this.isRender = true;
            this.filterLoading = false;
        },
        addToDataGrids(payload) {
            this.closePicker();
            this.isRender = false;
            this.filterLoading = true;

            this.displayedToolTab = 'Data Table';

            const selectedDataset = payload.selectedDatasetRow;
            const selectedDatasetIndex = this.dataGrids.findIndex(
                (obj) => obj.DatasetId === selectedDataset.DatasetId
            );
            if (selectedDatasetIndex !== -1) {
                setTimeout(() => {
                    this.tabChanged(this.dataGrids[selectedDatasetIndex]);
                    this.$refs.commonTableTabs.setActiveTabIndex(
                        selectedDatasetIndex
                    );
                }, 100);
            } else {
                this.dataGrids.push(selectedDataset);
                setTimeout(() => {
                    this.$refs.commonTableTabs.setActiveTabIndex(
                        this.dataGrids.length - 1
                    );

                    this.tabChanged(this.dataGrids[this.dataGrids.length - 1]);
                }, 100);
            }
            setTimeout(() => {
                this.isRender = true;
                this.filterLoading = false;
            }, 150);
        },
        closeTab(tabName) {
            const index = this.dataGrids.findIndex(
                (grid) => grid.name === tabName
            );

            this.removeSelectedDatasets({
                projectId: this.ProjectID,
                datasetId: this.dataGrids[index].DatasetId,
            });
            let currentDatasetName = this.dataGrids[index].DatasetAlias;

            this.currentTabIndex = this.dataGrids.findIndex(
                (dataset) => dataset.DatasetAlias == this.dataSetName
            );

            this.dataGrids.splice(index, 1);

            if (this.dataSetName == currentDatasetName) {
                if (this.dataGrids.length !== 0) {
                    const newIndx = this.dataGrids[index] ? index : index - 1;

                    const activeTab = this.dataGrids[newIndx];
                    setTimeout(() => {
                        this.$refs.commonTableTabs.setActiveTabIndex(newIndx);
                        this.tabChanged(activeTab);
                    }, 100);
                } else {
                    this.datasetId = 0;
                    this.toolTabs = [];
                    this.openPicker();
                }
            } else if (this.currentTabIndex > index) {
                //Stay in the current tab if the indexes change because of splice
                setTimeout(() => {
                    this.$refs.commonTableTabs.setActiveTabIndex(
                        this.currentTabIndex - 1
                    );
                }, 2);
            }
        },
        resetDatagrid() {
            let grid = document.getElementById('PagedDataGrid');
            if (grid) {
                grid.innerHTML = '';
            }
        },
    },
    computed: {
        ...mapGetters('projects', {
            ProjectID: 'ProjectID',
            getSelectedProject: 'getSelectedProject',
        }),

        ...mapState({
            currentGridFilter: (state) => state.gridFilters.currentGridFilter,
            groupedTables: (state) => state.gridFilters.groupedTables,
        }),

        tabNames() {
            return this.toolTabs.map((tab) => tab.name);
        },
        currentProperties() {
            let toReturn = {};
            switch (this.displayedToolTab) {
                case 'Data Table':
                    toReturn = {
                        datasetId: this.datasetId,
                        projectIds: this.ProjectID,
                        dataSetName: this.dataSetName,
                        datasetType: this.datasetType,
                        dateFilterColumn: this.dateFilterColumn,
                    };
                    break;
                case 'Data Posting Tool':
                case 'Contour':
                case 'X/Y Scatter Plot':
                    toReturn = {
                        datasetId: this.datasetId,
                        projectIds: this.ProjectID,
                        datasetType: this.datasetType,
                    };
                    break;
                case 'Dataset Export':
                    toReturn = {
                        dataSetId: this.datasetId,
                        datasetType: this.datasetType,
                        filter: this.filterId,
                        filterColName: 'SiteCode',
                        projectIds: [parseInt(this.ProjectID)],
                        fileName: this.getExportFileName(),
                        pdfExport: this.canExportPDF,
                        gridType: this.gridType,
                    };
                    break;
                case 'Title Block':
                    toReturn = {
                        renderTitle1: false,
                        dataSetId: this.datasetId,
                        dataSetName: this.dataSetName,
                        filterOptions: [
                            {
                                dataSetId: this.datasetId.toString(),
                                filter: this.filterId,
                                filterColName: 'SiteCode',
                                projectId: [parseInt(this.ProjectID)],
                            },
                        ],
                    };
                    break;
                case 'Advanced Filters':
                    toReturn = {
                        datasetId: this.datasetId,
                        projectIds: [parseInt(this.ProjectID)],
                    };
                    break;
                case 'Standard Crosstab':
                    toReturn = {
                        datasetId: this.datasetId,
                        projectIds: this.ProjectID,
                        dataGridData: [...this.gridData],
                    };
                    break;
            }
            return toReturn;
        },
        actions() {
            if (this.dateFilterColumn) {
                return [
                    { value: null, text: 'Actions', disabled: true },
                    { value: 'freeze', text: 'Freeze' },

                    {
                        label: 'Date Filter',
                        options: [
                            { value: 'Last12hours', text: 'Last 12 hours' },
                            { value: 'Last24hours', text: 'Last 24 hours' },
                            { value: 'Last48hours', text: 'Last 48 hours' },
                            { value: 'Last3days', text: 'Last 3 days' },
                            { value: 'Lastweek', text: 'Last week' },
                            { value: 'Lastmonth', text: 'Last month' },
                            { value: 'Lastquarter', text: 'Last quarter' },
                            { value: 'Lastyear', text: 'Last year' },
                            { value: 'Alltime', text: 'All time' },
                        ],
                    },
                ];
            } else {
                return [
                    { value: null, text: 'Actions', disabled: true },
                    { value: 'freeze', text: 'Freeze' },
                ];
            }
        },
    },

    watch: {
        async ProjectID(newProjectID, oldProjectID) {
            if (newProjectID != oldProjectID) {
                this.datasetId = 0;
                this.resetDatagrid();
                this.dataGrids = [];
                this.toolTabs = [];
                if (this.dataGrids.length !== 0) {
                    this.tabChanged(0);
                    this.$refs.commonTableTabs.setActiveTabIndex(
                        this.dataGrids.length - 1
                    );
                }
            }
        },
        datasetId(newDatasetId) {
            // wrap in an if, sometimes errors if not fully loaded the first time
            // document.getElementById('PagedDataGrid').innerHTML = '';
            if (newDatasetId !== 0) {
                if (this.$refs.commonToolTabs) {
                    this.$refs.commonToolTabs.setActiveTabIndex(0);
                }
                this.filterId = this.dataGrids.filter(
                    (dg) => dg.DatasetId === newDatasetId
                )[0].LegacyProjectIds[0];
                commonService
                    .getTools(this.projectIds, newDatasetId)
                    .then(async (res) => {
                        let tempTools = [{ name: 'Data Table' }];

                        //this.toolTabs = [{ name: 'Data Table' }];

                        // Check if dataset contains filter options
                        await datasetService
                            .getFilterOptions(newDatasetId, this.projectIds)
                            .then((resFilters) => {
                                if (resFilters.data.length > 0) {
                                    tempTools.push({
                                        name: 'Advanced Filters',
                                    });
                                }
                            })
                            .catch((error) => {});
                        tempTools.push({ name: 'Dataset Export' });
                        // Append additional tools
                        res.data.forEach((tool) => {
                            if (
                                this.dataTools.includes(tool.ToolName) &&
                                !tempTools.find((t) => t.name === tool.ToolName)
                            ) {
                                tempTools.push({ name: tool.ToolName });
                            }
                        });
                        //update the this.toolTabs
                        this.toolTabs = [...tempTools];
                    });
            }
        },
        displayedToolTab(newTool) {
            this.componentName = getComponentName(newTool);
        },
    },
    mounted() {
        this.componentName = getComponentName(this.displayedToolTab);
    },
    activated() {
        this.componentName = getComponentName(this.displayedToolTab);
    },
    async created() {
        this.$root.$refs.B = this;
        this.projectIds = this.ProjectID;
        localStorage.setItem('projectId', this.projectIds); //TODO: Please don't use localStorage, use props or store instead
    },
};
</script>
<style lang="scss" scoped>
.e-search-icon::before {
    content: '\e993';
    top: -11px !important;
    left: 10px;
}
.bottom-btn-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    background: #f4f4f4 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: center;
}

.datasets-container {
    direction: ltr;
}

.datasets-container a {
    color: black;
}
.tabs-end .nav-link {
    border: none;
    padding: 0px !important;
    height: 39px;
    margin-top: 10px !important;
}
.tabs-end a {
    display: flex;
    flex-flow: row wrap;
}
.plusDataset-img {
    height: 18px;
    width: 18px;
    margin-top: 0.4rem;
}
.tools-tab-btn-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    background-color: #f8f8f8;
}
.tabs-wrapper {
    width: 100%;
}
.btn-wrapper {
    width: 25%;
    background: #f8f8f8;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 59px;
    padding: 0 15px;
}

.item-btn {
    align-self: center;
}

.dataset-tab {
    border-bottom: none !important;
    background-color: #626262;
}
.dataset-tab a {
    border: none !important;
}
.actions-dropdown {
    height: 48px;
    margin-bottom: 0 !important;
}
.btn-wrapper__el {
    min-width: 100px !important;
    width: 135px;
}

@media only screen and (max-width: 820px) {
    .tabs-wrapper {
        width: 55%;
    }
    .btn-wrapper {
        width: 45%;
    }
}
@media only screen and (min-width: 821px) and (max-width: 1170px) {
    .tabs-wrapper {
        width: 60%;
    }
    .btn-wrapper {
        width: 40%;
    }
}
@media only screen and (min-width: 1171px) and (max-width: 1400px) {
    .tabs-wrapper {
        width: 70%;
    }
    .btn-wrapper {
        width: 30%;
    }
}
</style>
<style>
@import url(https://cdn.syncfusion.com/ej2/material.css);

a.router-link-active {
    color: black !important;
}

.table-data .dropdown-menu.show {
    display: block;
    margin-left: 23px;
}

.e-grid .e-content {
    background-color: #fff;
    margin-left: 3px !important;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
    flex-basis: auto !important;
}

::marker {
    text-decoration: none !important;
}

input:checked + .slider {
    background-color: #2196f3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.my-custom-floating-label {
    margin-bottom: 20px;
    display: inline-flex;
}

.normal_input {
    height: 48px !important;
}

.nav-tabs .nav-link,
.nav-tabs .nav-item.show .nav-link {
    font-size: 18px;
    font-weight: normal;
    margin-top: 0;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    background-color: transparent !important;
    border-top: transparent !important;
    border-left: transparent !important;
    border-right: transparent !important;
    font-size: 18px;
    font-weight: 500;
    margin-top: 0;
}

.last_col {
    width: 120px;
    position: absolute;
    top: auto;
    border-bottom: 0px !important;
}

th.last_col {
    border-bottom: 3px solid black !important;
    border-top: 0px solid #dee2e6 !important;
    border-top: 1px solid #dee2e6 !important;
    height: 47px;
}

ul li {
    list-style: none;
    font-size: 80%;
    font-weight: 400;
}

.filterGrid td.e-rowcell:nth-child(6) {
    color: blue !important;
    text-decoration: underline;
    cursor: pointer;
}

.e-grid .e-edit::before {
    content: url('../../assets/edit.svg') !important;
}

.e-grid .e-filter::before {
    content: url('../../assets/filter.svg') !important;
    left: 30px !important;
}

.e-grid .e-delete::before {
    content: url('../../assets/close_icon.svg') !important;
}

.e-btn.e-flat,
.e-css.e-btn.e-flat {
    background-color: White !important;
    border: none !important;
    text-align: center !important;
}

.e-btn.e-flat:hover,
.e-css.e-btn.e-flat:hover {
    background-color: #f5f5f5 !important;
}

.e-input-group .e-input-group-icon {
    color: #006395 !important;
}

.e-input-group .e-input-group-icon:hover {
    background-color: transparent !important;
}

.e-grid .e-gridcontent tr td:first-child,
.e-grid .e-gridcontent tr td:last-child {
    text-align: left !important;
}

.e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
    border-color: #000 !important;
    background-color: #000 !important;
    border-radius: 0 !important;
    /* width: 100%; */
}

.e-checkbox-wrapper .e-frame.e-stop,
.e-css.e-checkbox-wrapper .e-frame.e-stop {
    color: transparent !important;
}

.e-btn.e-flat.e-primary {
    background-color: #006395 !important;
    padding: 11px 10px !important;
    border-radius: 3px;
    border: 0px !important;
    color: #fff !important;
    text-transform: capitalize;
}

.e-dialog .e-btn {
    color: #006395 !important;
    text-transform: capitalize;
}

.e-grid .e-dialog.e-checkboxfilter {
    box-shadow: 0px 3px 6px #00000029 !important;
    -webkit-box-shadow: 0px 3px 6px #00000029 !important;
    -moz-box-shadow: 0px 3px 6px #00000029 !important;
}

.e-dialog .e-footer-content {
    text-align: left;
}

.e-checkbox-wrapper .e-frame,
.e-css.e-checkbox-wrapper .e-frame {
    border: 1px solid #000;
    border-radius: 0px;
}

.e-grid .e-checkboxlist {
    scrollbar-width: thin;
    scrollbar-color: #626262 #c6c6c6;
}

.e-grid .e-checkboxlist:-webkit-scrollbar {
    width: 3px;
}

.e-grid .e-checkboxlist:-webkit-scrollbar-track {
    background: transparent;
}

.e-grid .e-checkboxlist:-webkit-scrollbar-thumb {
    background-color: #000;
    border-radius: 6px;
    border: 3px solid transparent;
}

.e-grid .e-row.e-altrow {
    background: rgba(234, 234, 234, 0.5);
}

.e-grid .e-row {
    background: #fff;
}

#spinner {
    width: 900px;
    height: 500px;
}

.e-checkboxfilter .e-checkboxlist div:not(:first-child) {
    margin-left: 20px;
}
.e-dialog.e-checkboxfilter .e-checkboxfiltertext {
    width: 145px;
}
#grid-column89-flmdlg {
    background-color: #000 !important;
}
.e-grid .e-flmenu .e-popup-open {
    background-color: #000 !important;
}
.model_style {
    max-width: 100% !important;
}
.flt-tbl .e-pager .e-pagesizes {
    bottom: 42px !important;
}
.e-pager div.e-parentmsgbar {
    top: -50px !important;
    padding: 11px 0 6px 8px !important;
}

.e-pager .e-pagerconstant {
    margin-bottom: 6px !important;
}
.e-input-group.e-control-wrapper {
    margin-bottom: 6px !important;
}
.e-input-group.e-control-wrapper.e-ddl .e-input[readonly],
.e-float.input.e-control-wrapper.e-ddl input[readonly] {
    text-align: right;
    position: relative;
    top: 4px;
}
.e-input-group:not(.e-disabled) .e-control.e-dropdownlist ~ .e-ddl-icon,
.e-input-group.e-disabled.e-ddl .e-control.e-dropdownlist ~ .e-input-group-icon,
.e-control.e-dropdownlist .e-input-group.e-disabled.e-ddl .e-input-group-icon,
.e-control.e-dropdownlist .e-input-group.e-ddl .e-input-group-icon {
    top: 3px;
}
input.e-input,
.e-input-group input.e-input,
.e-input-group.e-control-wrapper input.e-input,
textarea.e-input,
.e-input-group textarea.e-input,
.e-input-group.e-control-wrapper textarea.e-input {
    padding: 0 !important;
}
.e-control.e-dropdownlist .e-input-group.e-ddl .e-input-group-icon,
.e-control.e-dropdownlist .e-input-group.e-disabled.e-ddl .e-input-group-icon,
.e-input-group.e-disabled.e-ddl .e-control.e-dropdownlist ~ .e-input-group-icon,
.e-input-group:not(.e-disabled) .e-control.e-dropdownlist ~ .e-ddl-icon {
    padding: 0px !important;
}
.e-grid .e-icon-filter.e-filtered::before {
    content: url('../../assets/filter-fill.svg') !important;
    left: 30px !important;
}
.flt-tbl .e-grid .e-icon-filter::before {
    left: 0px !important;
}
.flt-tbl .e-grid .e-icon-ascending::before {
    left: 55px !important;
}
.flt-tbl .e-grid .e-icon-descending::before {
    left: 55px !important;
}
.e-grid .e-filtered::before {
    color: #006395;
}

/* .flt-tbl .e-pager .e-pagerdropdown {
    width: 43px !important;
} */

div[id^='ej2_dropdownlist_'] {
    width: 80px !important;
}

.visibilityIcon {
    position: absolute;
    left: 46.5%;
    top: 400px;
    z-index: 2;
}
.visibilityText {
    position: absolute;
    left: 44%;
    top: 430px;
    z-index: 2;
}
.manage-form {
    margin: 0 auto;
    text-align: center;
    width: 499px;
}
.applyButton {
    background: #006395;
    color: white;
    border: none;
    float: right;
    cursor: pointer;
    font: normal normal normal 16px/20px IBM Plex Sans;
    letter-spacing: 0.02px;
    color: #ffffff;
}
.assignw edApply,
.cancelButton {
    padding: 14px 23.46px;
}
.cancelButton,
.cancelButton:focus,
.cancelButton:active,
.cancelButton:hover {
    background: transparent;
    color: #006395 !important;
    border: none;
    float: right;
    box-shadow: none;
    cursor: pointer;
    background-color: transparent !important;
}
</style>
